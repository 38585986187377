$line-color: hsl(180, 100%, 97%);

$xxl: 1400px;
$xl: 1200px;
$lg: 992px;
$md: 768px;
$sm: 576px;

#banner-container {
	// text-align: center;
	margin-top: 200px;
	display: flex;
	justify-content: center;
}
@media (max-width: $lg) {
	#banner-container {
		margin-top: 100px;
	}
}
@media (max-width: $sm) {
	#banner-container {
		margin-top: 70px;
	}
}

@media (max-width: $lg) {
	#banner-svg {
		width: 100%;
	}
}

#banner-texts {
	fill: $line-color;
}

#banner-lines {
	stroke: $line-color;
}

#banner-lines,
#banner-texts {
	transition: all 1s ease;
}

$translate-x: 20px;
$translate-y: 0px;
$animation-duration-in: 0.1s;
$animation-duration-out: 0.5s;

.upper,
.lower {
	transition: all $animation-duration-out ease-out;
}

/* cSpell: disable */

/*!
 * Hover.css (http://ianlunn.github.io/Hover/)
 * Version: 2.3.2
 * Author: Ian Lunn @IanLunn
 * Author URL: http://ianlunn.co.uk/
 * Github: https://github.com/IanLunn/Hover

 * Hover.css Copyright Ian Lunn 2017. Generated with Sass.
 */

.hvr-forward {
	display: inline-block;
	vertical-align: middle;
	transform: perspective(1px) translateZ(0);
	box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	transition-duration: $animation-duration-in;
	transition-property: transform;
}

.hvr-backward {
	display: inline-block;
	vertical-align: middle;
	transform: perspective(1px) translateZ(0);
	box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	transition-duration: $animation-duration-in;
	transition-property: transform;
	transform: translate(-$translate-x, -$translate-y);
}

.upper.hvr-forward {
	transform: translate($translate-x, -$translate-y);
}
.upper.hvr-backward {
	transform: translate(-$translate-x, -$translate-y);
}
.lower.hvr-forward {
	transform: translate($translate-x, $translate-y);
}
.lower.hvr-backward {
	transform: translate(-$translate-x, $translate-y);
}

$wobble-duration: 0.7s;

/* Wobble Vertical */
@-webkit-keyframes hvr-wobble-vertical {
	16.65% {
		transform: translateY(8px);
	}
	33.3% {
		transform: translateY(-6px);
	}
	49.95% {
		transform: translateY(4px);
	}
	66.6% {
		transform: translateY(-2px);
	}
	83.25% {
		transform: translateY(1px);
	}
	100% {
		transform: translateY(0);
	}
}
@keyframes hvr-wobble-vertical {
	16.65% {
		transform: translateY(8px);
	}
	33.3% {
		transform: translateY(-6px);
	}
	49.95% {
		transform: translateY(4px);
	}
	66.6% {
		transform: translateY(-2px);
	}
	83.25% {
		transform: translateY(1px);
	}
	100% {
		transform: translateY(0);
	}
}
.hvr-wobble-vertical {
	display: inline-block;
	vertical-align: middle;
	transform: perspective(1px) translateZ(0);
	box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	animation-name: hvr-wobble-vertical;
	animation-duration: $wobble-duration;
	animation-timing-function: ease-in-out;
	animation-iteration-count: 1;
}

/* Wobble Horizontal */
@-webkit-keyframes hvr-wobble-horizontal {
	16.65% {
		transform: translateX(8px);
	}
	33.3% {
		transform: translateX(-6px);
	}
	49.95% {
		transform: translateX(4px);
	}
	66.6% {
		transform: translateX(-2px);
	}
	83.25% {
		transform: translateX(1px);
	}
	100% {
		transform: translateX(0);
	}
}
@keyframes hvr-wobble-horizontal {
	16.65% {
		transform: translateX(8px);
	}
	33.3% {
		transform: translateX(-6px);
	}
	49.95% {
		transform: translateX(4px);
	}
	66.6% {
		transform: translateX(-2px);
	}
	83.25% {
		transform: translateX(1px);
	}
	100% {
		transform: translateX(0);
	}
}
.hvr-wobble-horizontal {
	display: inline-block;
	vertical-align: middle;
	transform: perspective(1px) translateZ(0);
	box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	animation-name: hvr-wobble-horizontal;
	animation-duration: $wobble-duration;
	animation-timing-function: ease-in-out;
	animation-iteration-count: 1;
}

/* Wobble To Bottom Right */
@-webkit-keyframes hvr-wobble-to-bottom-right {
	16.65% {
		transform: translate(8px, 8px);
	}
	33.3% {
		transform: translate(-6px, -6px);
	}
	49.95% {
		transform: translate(4px, 4px);
	}
	66.6% {
		transform: translate(-2px, -2px);
	}
	83.25% {
		transform: translate(1px, 1px);
	}
	100% {
		transform: translate(0, 0);
	}
}
@keyframes hvr-wobble-to-bottom-right {
	16.65% {
		transform: translate(8px, 8px);
	}
	33.3% {
		transform: translate(-6px, -6px);
	}
	49.95% {
		transform: translate(4px, 4px);
	}
	66.6% {
		transform: translate(-2px, -2px);
	}
	83.25% {
		transform: translate(1px, 1px);
	}
	100% {
		transform: translate(0, 0);
	}
}
.hvr-wobble-to-bottom-right {
	display: inline-block;
	vertical-align: middle;
	transform: perspective(1px) translateZ(0);
	box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	animation-name: hvr-wobble-to-bottom-right;
	animation-duration: $wobble-duration;
	animation-timing-function: ease-in-out;
	animation-iteration-count: 1;
}

/* Wobble To Top Right */
@-webkit-keyframes hvr-wobble-to-top-right {
	16.65% {
		transform: translate(8px, -8px);
	}
	33.3% {
		transform: translate(-6px, 6px);
	}
	49.95% {
		transform: translate(4px, -4px);
	}
	66.6% {
		transform: translate(-2px, 2px);
	}
	83.25% {
		transform: translate(1px, -1px);
	}
	100% {
		transform: translate(0, 0);
	}
}
@keyframes hvr-wobble-to-top-right {
	16.65% {
		transform: translate(8px, -8px);
	}
	33.3% {
		transform: translate(-6px, 6px);
	}
	49.95% {
		transform: translate(4px, -4px);
	}
	66.6% {
		transform: translate(-2px, 2px);
	}
	83.25% {
		transform: translate(1px, -1px);
	}
	100% {
		transform: translate(0, 0);
	}
}
.hvr-wobble-to-top-right {
	display: inline-block;
	vertical-align: middle;
	transform: perspective(1px) translateZ(0);
	box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	animation-name: hvr-wobble-to-top-right;
	animation-duration: $wobble-duration;
	animation-timing-function: ease-in-out;
	animation-iteration-count: 1;
}
